<template>
  <section v-if="error">
    <p>ERROR:....</p>
  </section>
  <section v-if="loading">
    <p>CARGANDO...</p>
  </section>

  <section v-else id="principal">
    <ModalInicio v-if="!modalInicio" />
    <BloqueUno :bloque_uno="jsonHome.bloque_uno" />
    <BloqueDos/>
    <BloqueTres :bloque_tres="jsonHome.bloque_tres" />
    <Footer />
  </section>
</template>

<script>
//Import de los recursos necesarios para la recogida de información de la BBDD
import { useQuery } from "@vue/apollo-composable";
import QueryDatosBloques from "../graphql/DatosBloques.gql";
//Fin de los import para la BBDD

//import BloqueUno from "@/components/bloqueUno/BloqueUno.vue";
import { ref, defineAsyncComponent, computed, provide} from "vue";

export default {
  name: "LaFelgueraView",
  setup() {
    const modalInicio = ref(false);
    const { result, loading, error } = useQuery(QueryDatosBloques.getDatosBloques, { "id": "felguera" });
    const jsonHome = computed(() => result.value?.getBloques.bloques ?? [])
    provide('jsonHome', jsonHome)
    return { jsonHome, loading, error, modalInicio };
  },
  components: {
    ModalInicio: defineAsyncComponent(() =>
      import(
        /* webpackChunkName: "ModalInicio" */ "@/components/modalInicio/ModalInicio.vue"
      )
    ),
    BloqueUno: defineAsyncComponent(() =>
      import(
        /* webpackChunkName: "BloqueUno" */ "@/components/bloqueUno/BloqueUno.vue"
      )
    ),
    BloqueDos: defineAsyncComponent(() =>
      import(
        /* webpackChunkName: "BloqueDos" */ "@/components/bloqueDos/BloqueDos.vue"
      )
    ),
    BloqueTres: defineAsyncComponent(() =>
      import(
        /* webpackChunkName: "BloqueTres" */ "@/components/bloqueTres/BloqueTres.vue"
      )
    ),
    Footer: defineAsyncComponent(() =>
      import(
        /* webpackChunkName: "Footer-home" */ "@/components/footer/Footer.vue"
      )
    ),
  },
  mounted() {
    this.modalInicio = this.getCookie("ba-san");
    if (!this.modalInicio) {
      document.getElementsByTagName("html")[0].style.overflowY = "hidden";
    }
  },
  methods: {
    getCookie(name) {
      const value = `; ${document.cookie}`;
      const parts = value.split(`; ${name}=`);
      if (parts.length === 2) return parts.pop().split(";").shift();
    }
  },
};
</script>
